<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Customer Packages</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Customer Packages
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="table-responsive">
      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>
      <table class="table table-bordered table-hover" v-if="!loading">
        <thead class="thead-light">
          <tr>
            <th colspan="2"></th>
            <th scope="col" colspan="5">Packages Customer</th>
          </tr>
        </thead>
        <thead class="thead-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Code</th>
            <th scope="col">Status</th>
            <th scope="col">Payment</th>
            <th scope="col">Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="data.length == 0">
            <td colspan="7">Packages is empty</td>
          </tr>
          <tr v-for="(packages, p) in data" :key="p">
            <th scope="row">{{ p + 1 }}</th>
            <td>{{ packages.customer_detail.name }}</td>
            <td class="cl-primary f-bold">{{ packages.code }}</td>
            <td>
              <span
                class="badge badge-success p-2"
                v-if="packages.status_approval == 'approved'"
                >Approved</span
              >
              <span
                class="badge badge-primary p-2"
                v-if="packages.status_approval == 'waiting'"
                >Waiting</span
              >
              <span
                class="badge badge-warning p-2"
                v-if="packages.status_approval == 'pending'"
                >Pending</span
              >
              <span
                class="badge badge-danger p-2"
                v-if="packages.status_approval == 'rejected'"
                >Rejected</span
              >
            </td>
            <td>
              <span
                class="badge badge-success p-2"
                v-if="packages.status_payment == 'paid'"
                >Paid</span
              >
              <span
                class="badge badge-secondary p-2"
                v-if="packages.status_payment == 'down payment 50%'"
                >Down Payment</span
              >
              <span
                class="badge badge-primary p-2"
                v-if="packages.status_payment == 'waiting'"
                >Waiting</span
              >
              <span
                class="badge badge-warning p-2"
                v-if="packages.status_payment == 'pending'"
                >Pending</span
              >
              <span
                class="badge badge-danger p-2"
                v-if="packages.status_payment == 'rejected'"
                >Pending</span
              >
            </td>
            <td>{{ moment(packages.date_submission).format("DD/MM/YYYY") }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <router-link v-if="role == 'admin'"
                  :to="
                    '/admin/customer-packages/detail/' +
                    packages.code +
                    '/' +
                    packages.id
                  "
                  ><span class="material-icons"> launch </span></router-link
                >
                <router-link v-else-if="role == 'finance'"
                  :to="
                    '/admin-finance/customer-packages/detail/' +
                    packages.code +
                    '/' +
                    packages.id
                  "
                  ><span class="material-icons"> launch </span></router-link
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "CustomerPackages",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
      role: localStorage.getItem("role")
    };
  },

  mounted() {
    this.getAllPackages();
  },

  methods: {
    getAllPackages() {
      const endpoint = "customer-package";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.customer_package;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },
};
</script>
